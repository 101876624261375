import styled, { css } from "styled-components";

import StatueLeft from "assets/svgs/pages/index/main-screen/statue_left.svg";
import StatueRight from "assets/svgs/pages/index/main-screen/statue_right.svg";
import ArrowBorder from "assets/svgs/pages/index/main-screen/arrow_border.svg";
import ArrowDown from "assets/svgs/pages/index/main-screen/arrow_down.svg";
import Buba from "assets/svgs/pages/index/main-screen/buba.svg";
import FigmaCloud from "assets/svgs/pages/index/main-screen/figma_cloud.svg";
import FigmaLogo from "assets/svgs/pages/index/main-screen/figma_logo.svg";
import FlagJapan from "assets/svgs/pages/index/main-screen/flag_japan.svg";
import FlagSwiss from "assets/svgs/pages/index/main-screen/flag_swiss.svg";
import GranichHeartKPop from "assets/svgs/pages/index/main-screen/granich_heart_k_pop.svg";
import Kiki from "assets/svgs/pages/index/main-screen/kiki.svg";
import Star from "assets/svgs/pages/index/main-screen/star.svg";
import TelegramAirplane from "assets/svgs/pages/index/main-screen/telegram_airplane.svg";
import VkVideo from "assets/svgs/pages/index/main-screen/vk_video.svg";
import Youtube from "assets/svgs/pages/index/main-screen/youtube.svg";
import ButtonComponent from "components/layout/button";

export const Section = styled.div`
  padding-top: 6vw;
  background: white;

  @media only screen and (max-width: 575px) {
    padding-top: 31vw;
  }
`;

export const Title = styled.h1`
  font-family: InterDisplay, sans-serif;
  font-weight: 900;
  font-size: 5.9vw;
  color: var(--granich-black-title);
  line-height: 0.89;
  letter-spacing: -0.2vw;
  position: absolute;
  top: 2.3vw;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  max-width: 30vw;
  margin: 0;

  @media only screen and (max-width: 575px) {
    width: 70%;
    max-width: 100%;
    font-size: 13.5vw;
    margin-bottom: 0;
    margin-top: 4.4vw;
    letter-spacing: -0.1vw;
    line-height: 0.95;
    position: relative;
    z-index: 2;
  }
`;
export const Descr = styled.div`
  color: var(--granich-grey);
  letter-spacing: 0.01vw;
  font-size: 2.6vw;
  line-height: 1;
  font-family: EB Garamond;
  font-style: italic;
  position: absolute;
  top: 15vw;
  left: 51%;
  text-align: center;
  transform: translateX(-50%);
  max-width: 19vw;
  z-index: 2;
  font-weight: 500;
  span {
    color: var(--granich-red);
    position: relative;
    left: -0.3vw;
  }
  @media only screen and (max-width: 575px) {
    font-size: 7.5vw;
    max-width: 90%;
    margin-top: 21vw;
    transform: translateX(-53%);
  }
`;

export const Wrapper = styled.div`
  padding: 12.2vw 0 5vw 0;
  min-height: 40vw;

  @media only screen and (max-width: 575px) {
    padding: 0;
    min-height: 225vw;
  }
`;

const MobileCubeStyle = css`
  position: absolute;
  content: "";
  left: 50%;
  transform: translateX(-50%);
  width: 3vw;
  height: 3vw;
  background: var(--granich-black);
`;

const CubeRemoteness = "-8vw";

export const LogInBlock = styled.div`
  position: absolute;
  bottom: 3.1vw;
  left: 21.1vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 575px) {
    bottom: auto;
    top: 79.5vw;
    left: 50%;
    transform: translateX(-50%);
    :before {
      ${MobileCubeStyle}
      top: ${CubeRemoteness};
    }
    :after {
      ${MobileCubeStyle}
      transform: translate(-50%, 50%);
      bottom: ${CubeRemoteness};
    }
  }
`;

export const LogInText = styled.div`
  text-align: center;
  font-size: 1.2vw;
  letter-spacing: -0.01vw;
  margin-bottom: 0.8vw;

  span {
    font-family: Inter;
    font-size: 1.2vw;
    letter-spacing: -0.01vw;
    text-align: center;
    display: block;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.7vw;
    margin-bottom: 4vw;

    span {
      margin-top: -0.5vw;
      font-size: 4.7vw;
    }
  }
`;

export const StyledButtonComponent = styled(ButtonComponent)`
  padding: 1.15vw 1.3vw;
  svg {
    width: 1.9vw;
    min-width: 1.9vw;
    height: 1.9vw;
  }
  span {
    font-size: 1.1vw;
    margin-left: 0.26vw;
  }

  @media only screen and (max-width: 575px) {
    padding: 4.15vw 6.3vw;
    span {
      font-size: 4.2vw;
      margin-left: 1vw;
    }
    svg {
      width: 7.9vw;
      min-width: 7.9vw;
      height: 7.9vw;
    }
  }
`;

export const StyledStatueLeft = styled(StatueLeft)`
  position: absolute;
  bottom: 0;
  left: 3vw;
  width: 12.9vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledStatueRight = styled(StatueRight)`
  position: absolute;
  bottom: 0;
  right: 1.9vw;
  width: 11.3vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const ScrollDownWrapper = styled.div`
  position: relative;
`;

export const ArrowAnimation = styled.div`
  position: absolute;
  bottom: 3.1vw;
  right: 17.5vw;
  width: 11vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 575px) {
    bottom: auto;
    top: 125.5vw;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 45vw;
  }
`;

export const ScrollDownText = styled.div`
  text-align: center;
  font-size: 1.2vw;
  margin-bottom: 0.5vw;

  @media only screen and (max-width: 575px) {
    font-size: 4.7vw;
    margin-bottom: 1.5vw;
  }
`;

export const StyledArrowBorder = styled(ArrowBorder)`
  width: 3vw;

  @media only screen and (max-width: 575px) {
    width: 12vw;
  }
`;

export const StyledArrowDown = styled(ArrowDown)`
  position: absolute;
  top: 1.3vw;
  left: 50%;
  width: 1.5vw;
  transform: translateX(-50%);
  animation: animate 1s ease-in-out infinite;

  @keyframes animate {
    0% {
      top: 0.8vw;
    }

    50% {
      top: 1.5vw;
    }

    100% {
      top: 0.8vw;
    }
  }

  @media only screen and (max-width: 575px) {
    width: 6.5vw;

    @keyframes animate {
      0% {
        top: 3vw;
      }

      50% {
        top: 6vw;
      }

      100% {
        top: 3vw;
      }
    }
  }
`;

export const StyledBuba = styled(Buba)`
  position: absolute;
  top: 2.5vw;
  right: 9vw;
  width: 5vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledFigmaCloud = styled(FigmaCloud)`
  position: absolute;
  top: 1.5vw;
  left: 1.5vw;
  width: 6vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 37.5vw;
    width: 24vw;
    left: 8.5vw;
  }
`;

export const StyledFigmaLogo = styled(FigmaLogo)`
  position: absolute;
  top: 2.5vw;
  left: 8.4vw;
  width: 4.4vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledFlagJapan = styled(FlagJapan)`
  position: absolute;
  bottom: 21.8vw;
  right: 12.1vw;
  width: 6vw;

  @media only screen and (max-width: 575px) {
    bottom: 4.8vw;
    right: -0.5vw;
    width: 22vw;
  }
`;

export const StyledFlagSwiss = styled(FlagSwiss)`
  position: absolute;
  bottom: 21.5vw;
  left: 4vw;
  width: 5.6vw;

  @media only screen and (max-width: 575px) {
    bottom: 4.5vw;
    left: -0.5vw;
    width: 21.6vw;
  }
`;

export const StyledGranichHeartKPop = styled(GranichHeartKPop)`
  position: absolute;
  bottom: 0;
  left: 51%;
  transform: translateX(-50%);
  width: 7vw;

  @media only screen and (max-width: 575px) {
    width: 27.5vw;
    left: 50%;
  }
`;

export const StyledKiki = styled(Kiki)`
  position: absolute;
  top: 7.8vw;
  right: 1.3vw;
  width: 5vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledStar = styled(Star)`
  position: absolute;
  bottom: 13.3vw;
  left: 1.7vw;
  width: 3.1vw;

  @media only screen and (max-width: 575px) {
    left: 3.7vw;
    top: 5.3vw;
    width: 11.1vw;
    bottom: auto;
  }
`;

export const StyledTelegramAirplane = styled(TelegramAirplane)`
  position: absolute;
  top: 13.4vw;
  left: 15.4vw;
  width: 3vw;

  @media only screen and (max-width: 575px) {
    top: auto;
    bottom: 45.4vw;
    left: auto;
    right: 19.4vw;
    width: 12vw;
  }
`;

export const StyledVkVideo = styled(VkVideo)`
  position: absolute;
  top: 9.5vw;
  right: 7.8vw;
  width: 3vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const StyledYoutube = styled(Youtube)`
  position: absolute;
  top: 2.7vw;
  right: 2.1vw;
  width: 3.5vw;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;
