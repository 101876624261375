import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, SectionTitle, Section } from "components/style";

export const StyledSectiom = styled(Section)`
  @media only screen and (max-width: 575px) {
    margin-bottom: 10vw;
  }
`;

export const StyledContainer = styled(Container)``;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 3vw;
  }
`;

export const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 1.4vw;
  font-size: 4.5vw;
  margin-right: 2vw;
  @media only screen and (max-width: 575px) {
    letter-spacing: 0;
    font-size: 8vw;
    margin-bottom: 2vw;
  }
`;

export const SectionSubTitle = styled.div`
  font-size: 1.24vw;
  max-width: 44%;
  position: relative;
  top: -0.5vw;
  line-height: 1.5;
  color: #969696;
  font-family: InterDisplay;
  @media only screen and (max-width: 575px) {
    font-size: 5.05vw;
    line-height: 1.45;
    max-width: 100%;
  }
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2vw;
  z-index: 2;

  @media only screen and (max-width: 575px) {
    padding: 0 5vw;
    grid-column-gap: 5vw;
    background: var(--granich-background-color);
  }
`;

export const MainWrapperScroll = styled.div`
  @media only screen and (max-width: 575px) {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    left: -5vw;
    width: 100vw;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      display: none;
      -webkit-appearance: none;
    }

    ::-webkit-scrollbar-track {
      display: none;
    }
  }
`;

export const ReviewCard = styled.div`
  border-radius: 1vw;
  overflow: hidden;
  position: relative;
  background: white;
  border-radius: 1.3vw;
  padding: 2vw;

  @media only screen and (max-width: 575px) {
    border-radius: 5.5vw;
    padding: 6vw;
    padding-bottom: 3vw;
    min-width: 85vw;
  }
`;

export const ReviewerImage = styled(GatsbyImage)`
  width: 4vw;
  border: 0.1vw solid var(--granich-black);
  margin-bottom: 1vw;

  @media only screen and (max-width: 575px) {
    width: 19vw;
    border: 0.5vw solid var(--granich-black);
    margin-bottom: 4vw;
  }
`;

export const ReviewerName = styled.div`
  font-weight: bold;
  margin-bottom: 0.6vw;
  font-size: 1.24vw;

  @media only screen and (max-width: 575px) {
    font-size: 5vw;
    margin-bottom: 3vw;
  }
`;

export const ReviewerText = styled.div`
  line-height: 1.4;
  span {
    display: block;
    margin-bottom: 1vw;
    font-size: 1.24vw;
  }

  @media only screen and (max-width: 575px) {
    line-height: 1.5;
    max-width: 90vw;
    span {
      display: block;
      margin-bottom: 2.5vw;
      font-size: 4.94vw;
    }
  }
`;
