import styled from "styled-components";
import ArrowIcon from "assets/svgs/arrow-black-right.svg";
import GranichMagic from "assets/svgs/pages/index/contacts/granich-magic.svg";

import { BlockTitle } from "../style";

export const SubmitButton = styled(ArrowIcon)`
  width: 3.6vw;
  min-width: 3.6vw;
  height: 3.6vw;
  min-height: 3.6vw;
  transition: all 0.2s ease;
  :hover {
    transform: scale(1.1);
  }

  @media only screen and (max-width: 575px) {
    width: 11vw;
    min-width: 11vw;
    height: 11vw;
    min-height: 11vw;
  }
`;

export const PrivacyPopupLink = styled.a``;

export const Section = styled.section``;

export const Wrapper = styled.div`
  width: 100%;
  background: var(--granich-red);
  border-radius: 0.6vw;
  position: relative;
  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
  }
`;

export const Container = styled.div``;

export const MailingTitle = styled(BlockTitle)`
  font-size: 1.8vw;
  min-width: 28.8vw;
  font-size: 2.43vw;
  padding-left: 0;
  font-family: InterDisplay;
  color: var(--granich-black-title);

  @media only screen and (max-width: 575px) {
    font-size: 8vw;
  }
`;

export const MailingMain = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.6vw;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 8vw;
  }
`;

export const MailingInfo = styled.div`
  display: flex;
  align-items: start;

  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const MailingInfoText = styled.div`
  max-width: 34.8vw;
  font-size: 1.35vw;
  margin-right: 1.5vw;
  color: var(--granich-grey);
  line-height: 1.3;

  svg {
    width: 1.3vw;
    min-width: 1.3vw;
  }

  :last-child {
    max-width: 24vw;
    margin-right: 0;
  }

  @media only screen and (max-width: 575px) {
    font-size: 4.7vw;
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 6vw;
    line-height: 1.4;

    svg {
      width: 5vw;
      min-width: 5vw;
    }

    :last-child {
      max-width: 100%;
      margin-bottom: 12vw;
    }
  }
`;

export const StyledGranichMagic = styled(GranichMagic)`
  position: absolute;
  bottom: 0;
  right: 4.3vw;
  width: 8vw;

  @media only screen and (max-width: 575px) {
    width: 31vw;
    right: 6.3vw;
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
`;

export const Input = styled.input`
  height: 2.7vw;
  border: none;
  border-bottom: 0.3vw solid var(--granich-black);
  padding: 0.5vw 0;
  width: 22vw;
  font-size: 1.5vw;
  font-weight: 600;
  margin-right: 1.1vw;
  position: relative;
  ${props =>
    props.errorStyle &&
    `
        border-color: var(--granich-purple-red);
        ::placeholder {
            color: var(--granich-purple-red);
        }
    `}

  @media only screen and (max-width: 575px) {
    width: 56vw;
    height: 10.5vw;
    font-size: 5.1vw;
    margin-right: 0.5vw;
    border-width: 1vw;
  }
`;

export const Button = styled.button`
  height: 3.4vw;
  border: none;

  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 575px) {
    min-width: 14vw;
    min-height: 14vw;
  }
`;

export const FormPolitikaLabel = styled.div`
  color: var(--granich-black);
  font-size: 0.8vw;
  line-height: 1.3;
  margin-left: 1.2vw;
  a {
    color: var(--granich-black);
    font-weight: 500;
    display: inline-block;
    position: relative;
    font-weight: 400;

    :after {
      content: "";
      height: 1px;
      width: 100%;
      background: var(--granich-black);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.1vw;
    margin-top: 4vw;
    line-height: 1.3;
    width: 100%;
    margin-left: 0;
    a {
      margin: 0;
    }
  }
`;

export const WrapperInputAndButton = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  width: 3.6vw;
  height: 3.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  @media only screen and (max-width: 575px) {
    width: 11vw;
    height: 11vw;
  }
`;
