// react/gatsby imports
import { useStaticQuery, graphql } from "gatsby";

const useFoundationQuery = () => {
  const prismicData = useStaticQuery(graphql`
    query foundationGraphDesignTestQuery {
      inDesignCourse: prismicCoursetype(
        data: { coursetitle: { text: { eq: "Granich InDesign" } } }
      ) {
        uid
        data {
          body {
            ... on PrismicCoursetypeDataBodyTags {
              id
              slice_type
              items {
                coursetag {
                  text
                }
              }
            }
            ... on PrismicCoursetypeDataBodyCourseCreators {
              slice_type
              items {
                coursecreator {
                  document {
                    ... on PrismicTeammembertype {
                      id
                      data {
                        teammemberimage {
                          gatsbyImageData
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          coursetitle {
            text
          }
          coursestartandend {
            text
          }
          coursestatus
          coursetype
          coursepreview {
            gatsbyImageData
          }
          courseadditionaltype {
            text
          }
          coursebuttontext {
            text
          }
          coursedescription {
            text
          }
          coursestart {
            text
          }
          courseduration {
            text
          }
        }
      }
      course: prismicCoursetype(
        data: { coursetitle: { text: { eq: "Фундамент Графдизайна" } } }
      ) {
        uid
        data {
          body {
            ... on PrismicCoursetypeDataBodyTags {
              id
              slice_type
              items {
                coursetag {
                  text
                }
              }
            }
          }
          coursetitle {
            text
          }
          coursedescription {
            text
          }
          coursestartandend {
            text
          }
          coursestatus
          coursestream
          coursetype
          course_price_solo
          course_price_group
          courseprice
          coursemainimage {
            gatsbyImageData
          }
          coursepolicy {
            richText
          }
        }
      }
      page: prismicPage(uid: { eq: "foundation-of-graphic-design" }) {
        uid
        data {
          seodescription {
            text
          }
          seoimage {
            url(imgixParams: { width: 1200 })
            thumbnails {
              vk {
                url(imgixParams: { width: 510 })
              }
            }
          }
        }
      }
    }
  `);

  return prismicData;
};

export default useFoundationQuery;
